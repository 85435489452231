@charset "UTF-8";

body {
    padding: 0;
    padding-top: 70px;
    margin: 0;
    color: #33464f;
    font-size: 18px;
}

header {
    margin: none !important;
    margin-bottom: .5em;
}

header h1 {
    font-size: 2em;
    color: #2b3f47;

}

.printInfo {
    color: #33464f;
    float: right;
    font-size: 1.4em;
    padding-left: 34px;
    /* background-image: url(/images/print.png); */
    background-repeat: no-repeat;
    background-size: 29px;
    background-position: center left;
    transition: all .3s;
    overflow: hidden
}

.printInfo:hover {
    color: #EC7258;
    /* background-image: url(/images/printO.png); */
}

.categoryHead {
    /* width: 100%; */
    height: 43px;
    background: #1f2b5e;
    padding: 14px;
    font-size: 1.35em;
    line-height: 18px;
    box-sizing: border-box;
    color: white;
    border-radius: 6px;
}


.infoTitle {
    font-size: .9em;
    color: #1f2b5e;
}


/** BASIC INORMATION **/

.basicInfo {
    color: #33464f;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.basicInfo *::selection {
    background: #EC7258;
    color: white;
    transition: all .3s
}

.propInfo *::selection {
    background: #EC7258;
    color: white
}

.contInfo *::selection {
    background: #EC7258;
    color: white
}

.basicInfo:after,
.propInfo:after,
.contInfo:after {
    content: " ";
    width: 100%;
    display: block;
    clear: both;
    height: 60px;
}

.basicInfoOuter {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border-right: solid 1px #7EB0CC;
    width: 33.33%;
}

@media only screen and (min-width: 600px)
{
    .titleInfo .basicInfoOuter{
        width: 50%;
    }
}

.basicInfoOuter:nth-child(3n) {
    border-right: none;
}

.titleInfo .basicInfoOuter:nth-child(2n) {
    border-right: none;
}

.basicInfoOuter:nth-child(1),
.basicInfoOuter:nth-child(2),
.basicInfoOuter:nth-child(3) {
    padding-top: 16px;
}


.basicInfoTitle,
.basicInfoText {
    box-sizing: border-box;
    float: left;
    padding: 20px 12px;
    border-bottom: solid 1px #7EB0CC;
    margin: 0;
    height: 65px;
    font-size: .9em;
}


.basicInfoTitle {
    width: 45%;
    white-space: nowrap
}

.basicInfoText {
    width: 55%;
}


/** PROPERTY INORMATION **/

.propInfo {
    box-sizing: border-box;
    font-size: .9em;
}

.propInfoTitle,
.propInfoText {
    height: 60px;
    box-sizing: border-box;
    padding: 20px 12px 0px 21px;
    border-bottom: solid 1px #7EB0CC;
}

.propInfoOuter:nth-child(1) {
    padding-top: 17px;
}


.propInfoTitle {
    width: 20%;
    float: left;
}

.propInfoText {
    width: 80%;
    float: left;
}

.propInfoTextL {
    width: 55%;
    float: left;
}

.propInfoTextR {
    width: 45%;
    float: left;
}

.propInfoTextR .infoTitle {
    margin-right: 6px;
}

.propInfoTextRtext {
    margin-right: 32px;
}

.propInfoTextRtext:nth-child(1) {
    margin-right: 0px;
}

/** CONTACT INORMATION **/
@media only screen and (min-width: 680px){
.contInfo {
    width: 50%;
    display: inline-block;
}
}

@media only screen and (min-width: 1000px){
    .contInfo {
        width: 33.33%;
    }
}

.contInfoOuter {
    width: calc(100% - 11px);
    float: left;
    box-sizing: border-box;
    /* height: 210px; */
    padding: 24px 24px 0px;
    /* border-right: solid 1px #7EB0CC;
    border-bottom: solid 1px #7EB0CC; */
    background-color: #fff;
    margin-bottom: 16px;
    margin-right: 16px;
    border-radius: 8px;
}

.contInfoOuter:nth-child(3n) {
    border-right: none;
    margin-right: 0;
}

.contInfoName {
    margin-top: 16px;
    color: #EC7258;
}

.contInfoText {
    margin-top: 16px;
    font-size: 15px;
}

.conEmail {
    text-decoration: underline;
}



@media only screen and (max-width: 1350px) {
    body {
        font-size: 16px
    }
}


/** iPad Portrait**/

@media only screen and (max-width: 1000px) {

    /** Basic Info 2 columns/Width and Outlines**/

    .basicInfoOuter {
        width: 50%;
        border-right: solid 1px #7EB0CC;
        padding: 0;
    }

    .basicInfoOuter:nth-child(3) {
        padding-top: 0px;
    }

    .basicInfoOuter:nth-child(3n) {
        border-right: solid 1px #7EB0CC;
    }

    .basicInfoOuter:nth-child(2n) {
        border-right: none;
    }



    /** Property Info **/


    .propInfoOuter:nth-child(1) {
        height: 105px;
    }

    .propInfoText,
    .propInfoTitle {
        height: 85px;
    }

    .propInfoTitle {
        width: 30% !important;
    }

    .propInfoText {
        width: 70%;
    }

    .propInfoTextL {
        width: 100%;
        margin-bottom: 10px;
    }

    .propInfoTextR {
        width: 100%;
    }


    /** Contact Info 2 columns/Width and Outlines**/


    .contInfoOuter:nth-child(3n) {
        border-right: solid 1px #7EB0CC;
    }

    .contInfoOuter:nth-child(2n) {
        border-right: none;
    }
}

@media only screen and (max-width: 600px) {

    header h1 {
        font-size: 1.6em
    }

    .printInfo {
        font-size: 1.1em;
        padding-left: 28px;
        background-size: 24px;
        margin-top: 3px
    }



    /** Basic Info 2 columns/Width and Outlines**/
    .basicInfoOuter {
        width: 100%;
        border-right: none;
        padding: 0;
    }

    .basicInfoTitle,
    .basicInfoText {
        padding: 17px 10px 0px 15px;
        height: 49px;
        font-size: .9em;
    }

    .basicInfoTitle {
        width: 40%;
        white-space: nowrap;
        float: left;
    }

    .basicInfoText {
        width: 55%;
        float: right
    }

    .basicInfoOuter:nth-child(1),
    .basicInfoOuter:nth-child(2),
    .basicInfoOuter:nth-child(3) {
        padding-top: 0px;
    }

    .basicInfoOuter:nth-child(3n) {
        border-right: none;
    }

    .basicInfoOuter:nth-child(2n) {
        border-right: none;
    }



    /** Property Info **/



    .propInfoOuter:nth-child(1) {
        padding-top: 10px;
    }

    .propInfoOuter {
        height: auto;
    }

    .propInfoTitle,
    .propInfoText {
        padding-bottom: 80px;
        padding-left: 17px;
        height: 130px;
    }

    .propInfoTitle {
        width: 23% !important;
    }

    .propInfoText {
        width: 74%;
        float: right;
    }

    .propInfoTextL {
        width: 100%;
        margin-bottom: 0px;
    }

    .propInfoTextR {
        width: 100%;
    }

    .propInfoTextR .infoTitle:after {
        content: ":"
    }

    .propInfoTextRtext:after {
        content: "\a";
        white-space: pre;
    }


    /** Contact Info 2 columns/Width and Outlines**/


    .contInfoOuter {
        width: 100%;
        border-right: none;
        height: 17em
    }

    .contInfoOuter:nth-child(3n) {
        border-right: none;
    }

    .contInfoOuter:nth-child(2n) {
        border-right: none;
    }
}

table.fileList{
    width: 100%;
}